<template>
  <div class="import import-companies">
    <Banner>
      <div class="banner-content">
        <MeepIconSettings class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.setting") }}</span>
      </div>
    </Banner>
    <PageHeader
      v-model="query"
      :title="headerTitle"
      :has-back="true"
      :has-actions="!isFinish"
      :has-search="hasSearch"
      @back="goBack()"
    >
      <md-button
        v-if="isEditBtn"
        class="md-raised header-action header-action-icon"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        @click="onEditClick"
      >
        <MeepIconEdit class="app-icon" />
      </md-button>
      <md-button
        v-if="isDeleteBtn"
        class="md-raised header-action header-action-icon"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        @click="onDeleteClick"
      >
        <MeepIconActionDelete class="app-icon" />
      </md-button>
      <template #left>
        <div class="import__notifications">
          <h3>
            {{
              $t("import-companies.notifications-text", {
                count: newCompanies.length,
              })
            }}
          </h3>
        </div>
      </template>
    </PageHeader>
    <div class="import-content page-layout">
      <confirm-action-modal
        v-model="isConfirmModalOpen"
        :title="
          importErrorLine
            ? $t('import-companies.error-modal-title')
            : $t('import-companies.modal-title')
        "
        :description="
          importErrorLine
            ? $t('import-companies.error-modal-description')
            : $t('import-companies.modal-description')
        "
        :question="importErrorLine ? '' : $t('import.modal-question')"
        :cta-yes="$t('confirm-action-modal.yes')"
        :cta-no="$t('confirm-action-modal.no')"
        @close="closeConfirmModal"
        @confirm="handleUpload"
      />

      <div class="import-new-card-container">
        <p class="import-new-card-error-line" v-if="importErrorLine">
          {{ errorText }}
        </p>
        <!-- sortable list -->
        <sortable-list
          class="page-list"
          default-sort="name"
          default-sort-order="asc"
          :selectable="isFinish ? 'single' : 'multiple'"
          :parameters="listParameters"
          :items="getSearchs"
          @selected="onSelect"
        >
        </sortable-list>

        <edit-modal
          v-if="isEditModal"
          @close="onCloseModal"
          :dataEdit="companySelected"
          typeCom="editCompany"
          @change-name="changeName"
        >
        </edit-modal>

        <confirm-action
          v-if="isConfirmDelete"
          :text="confirmDeleteText"
          :object-to-act-upon="{}"
          @close="isConfirmDelete = false"
          @confirm="onConfirmDelete"
        >
        </confirm-action>

        <md-card v-if="!isFinish" class="new-companies__createUser">
          <div class="md-layout">
            <div class="new-companies__createUser__content">
              <p class="new-companies__createUser__text">
                {{ $t("import-companies.create-user") }}
              </p>
              <p class="new-companies__createUser__note">
                {{ $t("import-companies.create-user-note") }}
              </p>
              <div class="new-companies__createUser__radio">
                <md-radio v-model="createUser" :value="true"
                  >{{ $t("common.yes") }}
                </md-radio>
                <md-radio v-model="createUser" :value="false"
                  >{{ $t("common.no") }}
                </md-radio>
              </div>
            </div>
          </div>
        </md-card>
        <div
          class="md-layout import-buttons md-alignment-center-right"
          :class="{
            'import-buttons-fullwidth': isFinish,
          }"
        >
          <md-button class="md-primary--inverted" @click="goBack()">
            {{ $t("common.start-over") }}
          </md-button>
          <md-button
            v-if="!isFinish"
            class="md-primary"
            @click.native="showConfirmModal()"
          >
            {{ $t("common.import") }}
          </md-button>
          <md-button v-else class="md-primary" @click="goBack()">
            {{ $t("common.finish") }}
          </md-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Fuse from "fuse.js";
import vueXlsxTable from "@deevotechvn/vue-xlsx-table";
import { mapActions, mapGetters } from "vuex";
import _omit from "lodash/omit";
import _isEqual from "lodash/isEqual";
import _cloneDeep from "lodash/cloneDeep";

import PageHeader from "@/components/PageHeader";
import EditModal from "@/components/modal/EditModal.vue";
import Banner from "@/components/Banner";
import confirmActionModal from "../../components/modal/ConfirmModal";
import ConfirmAction from "@/components/modal/confirm-action.vue";

import MeepIconSettings from "@/components/icons/MeepIconSettings.vue";
import MeepIconActionDelete from "@/components/icons/MeepIconActionDelete.vue";
import MeepIconEdit from "@/components/icons/MeepIconEdit.vue";

import companiesModel from "../../model/companies";

import HasBackMixin from "@/mixins/HasBackMixin";

Vue.use(vueXlsxTable, { rABS: false });

export default {
  name: "CompaniesImport",

  components: {
    PageHeader,
    "confirm-action-modal": confirmActionModal,
    Banner,
    EditModal,
    MeepIconSettings,
    MeepIconActionDelete,
    MeepIconEdit,
    ConfirmAction,
  },

  mixins: [HasBackMixin],

  data() {
    return {
      query: "",
      createUser: true,
      isConfirmModalOpen: false,
      isConfirmDelete: false,
      confirmDeleteText: {},
      isFinish: false,
      errorText: this.$t("import-companies.error-line"),
      isEditModal: false,
      listCompaniesSelected: [],
      companySelected: {},
      listParameters: [
        {
          name: "Nom de l'entreprise",
          key: "name",
          class: "primary-item",
        },
        {
          name: "SIREN",
          key: "data.siren",
        },
        {
          name: "Dirigeant",
          format: (_, item) => {
            return `${item.data.firstName} ${item.data.lastName}`;
          },
        },
        {
          name: "Email",
          key: "data.email",
        },
        {
          name: "URL",
          key: "data.url",
        },
        {
          name: "Adresse",
          key: "data.adresse",
        },
        {
          name: "Validation",
          format: (_, item) => {
            return item.messageError ? item.messageError : item.status;
          },
        },
      ],
      newCompaniesClone: [],
    };
  },

  computed: {
    ...mapGetters([
      "isAdmin",
      "isCollab",
      "isClient",
      "isJEP",
      "newCompanies",
      "importErrorLine",
    ]),

    isAnyError() {
      const newArr = this.newCompaniesClone.filter(item => item.messageError);
      return !!newArr.length;
    },

    headerTitle() {
      return this.isFinish
        ? this.$t("import.results-title")
        : this.$t("import-companies.title");
    },

    hasSearch() {
      return this.newCompaniesClone.length !== 0;
    },

    confirmModalText() {
      return {
        header: this.$t("import-companies.importer-title"),
        question: this.$t("import.confirm-modal.description"),
      };
    },

    getSearchs() {
      if (this.query === "") {
        return this.newCompaniesClone;
      }

      const fuse = new Fuse(this.newCompaniesClone, {
        shouldSort: true,
        threshold: 0.4,
        distance: 100,
        keys: ["data.name"],
      });
      return fuse.search(this.query).map(item => item.item);
    },

    isEditBtn() {
      return this.listCompaniesSelected.length === 1;
    },

    isDeleteBtn() {
      return this.listCompaniesSelected.length > 0;
    },
  },

  mounted() {
    this.newCompaniesClone = _cloneDeep(this.newCompanies);
    if (!this.newCompanies.length) {
      this.$router.push("/dashboard/setting/import");
    }
  },
  methods: {
    ...mapActions(["SET_IMPORT_COMPANIES", "SET_IMPORT_ERROR_LINE"]),
    async handleUpload() {
      const companyName = [];
      let message = "";
      let newData = [];
      newData = await Promise.all(
        this.newCompaniesClone.map(async company => {
          if (company.isValid) {
            try {
              if (company.data.email) {
                company.data.createUser = this.createUser;
              } else {
                company.data.createUser = false;
              }
              await companiesModel.create(company.data);
              if (this.createUser && company.data.email) {
                companyName.push(company.data.name);
              }
              company.status = this.$t("import-companies.create.success");
            } catch (err) {
              if (!company.messageError) {
                company.messageError = err.msg;
              }
              if (err.status === 421) {
                this.errorText = this.$t("import-companies.company-existed");
              }
              company.data.status = this.$t("import-companies.create.error");

              this.SET_IMPORT_ERROR_LINE(true);
            }
          }
          return company;
        })
      );

      this.newCompaniesClone = _cloneDeep(this.newCompaniesClone);

      this.SET_IMPORT_COMPANIES(newData);

      if (companyName.length > 1) {
        message = this.$t("import-companies.create.first-linked-account", {
          companyName: companyName[0],
        });
        for (let index = 1; index < companyName.length; index++) {
          if (index === companyName.length - 1) {
            message += this.$t("import-companies.create.last-linked-account", {
              companyName: companyName[index],
            });
          } else {
            message += this.$t(
              "import-companies.create.plural-linked-account",
              {
                companyName: companyName[index],
              }
            );
          }
        }
      } else {
        message = this.$t("import-companies.create.single-linked-account", {
          companyName: companyName[0],
        });
      }

      if (companyName.length !== 0) {
        this.$toasted.global.AppSucces({
          message: message,
        });
      }
      this.isFinish = true;
      this.isConfirmModalOpen = false;
    },

    onSelect(item) {
      this.listCompaniesSelected = item;
      this.companySelected = item[0];
    },

    onEditClick() {
      this.isEditModal = true;
    },

    onDeleteClick() {
      const isSingle = this.listCompaniesSelected.length === 1;
      this.confirmDeleteText = {
        header: isSingle
          ? this.$t("list-user.delete-modal-title-single")
          : this.$t("list-user.delete-modal-title-plural"),
        body: () => {
          return isSingle
            ? this.$t("list-user.delete-modal-body-single", {
                fullName: this.companySelected.name,
              })
            : this.$t("list-user.delete-modal-body-plural", {
                count: this.listCompaniesSelected.length,
              });
        },
        question: this.$t("list-user.modal-confirm-cta"),
      };
      this.isConfirmDelete = true;
    },

    onConfirmDelete() {
      let newCompaniesCloneDelete = _cloneDeep(this.newCompanies);
      for (let i = 0; i < this.listCompaniesSelected.length; i++) {
        newCompaniesCloneDelete = newCompaniesCloneDelete.filter(
          item => !_isEqual(this.listCompaniesSelected[i], item)
        );
      }
      this.newCompaniesClone = newCompaniesCloneDelete;
    },
    onCloseModal(data) {
      if (!data) {
        this.isEditModal = false;
      } else {
        data["firstName"] = data["firstname"];
        data["lastName"] = data["lastname"];
        const result = _omit(data, ["firstname", "lastname"]);
        this.saveData(result);
        this.isEditModal = false;
      }
    },

    changeName(newName) {
      this.companySelected.name = newName;
    },

    saveData(data) {
      this.companySelected.data = data;
    },

    showConfirmModal() {
      this.isConfirmModalOpen = true;
    },

    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },
  },

  watch: {
    isAnyError: function(newValue) {
      if (!newValue) {
        this.SET_IMPORT_ERROR_LINE(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variable.scss";
#app .import-companies {
  .new-companies {
    .header {
      font-weight: bold;
      display: flex;
      align-items: center;
    }

    &__createUser.md-card {
      padding: 30px;
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      box-shadow: none;
      border-radius: 18px;
      max-width: 850px;
    }

    &__createUser {
      &__text,
      &__note {
        margin-bottom: 10px;
        font-size: 20px;
      }

      &__text {
        font-family: var(--font-extrabold);
      }
    }

    .md-indeterminate {
      margin-top: 10px;
    }

    .md-table-row {
      margin: 0;

      .md-layout-item {
        padding: 35px 20px;
        word-break: break-word;
      }
    }
  }
  .page-header{
    margin-bottom: toRem(15);
    @include for-lg{
      margin-bottom: toRem(30);
    }
  }
  .import-buttons {
    max-width: 860px;
    &-fullwidth {
      max-width: 100%;
    }
    .md-button {
      width: 180px;
    }
  }
  .page-layout {
    height: calc(100vh - 420px);
    .import-new-card-container {
      .import-new-card-error-line {
        max-width: 70%;
        margin: 0 0 40px 0px;
        background: var(--bg-primary);
        width: 65%;
        padding: 20px 27px;
        color: var(--text-primary);
        font-family: var(--font-bold);
        font-size: 17px;
        border-radius: 18px;
      }
    }
  }

  .page-layout {
    height: 100%;
  }
}
</style>
